@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.imageContainer {
	margin: 0px 0px 20px;
	@include tablet() {
		margin: 0;
		grid-column: 5 / span 4;
		grid-row: 2;

		> div {
			top: 50%;
			margin-top: -50%;
		}
	}
}

.genericTitle {
	text-align: center;
	font-size: 3rem;
	line-height: 3.4rem;

	@include desktop() {
		font-size: 4rem;
		line-height: 4.6rem;
	}
}

.heading {
	text-align: center;

	p {
		margin-left: auto;
		margin-right: auto;
	}
}

.allDiets {
	.posContainer,
	.negContainer,
	.imageContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		@include tablet() {
			display: block;
			grid-row: 3;
		}
	}
}

.posContainer {
	text-align: center;
	@include tablet() {
		grid-column: 1 / span 4;
		grid-row: 2;
		align-self: center;
	}

	h3 {
		width: 100%;
	}
}

.negContainer {
	text-align: center;
	@include tablet() {
		grid-column: 9 / span 4;
		grid-row: 2;
		align-self: center;
	}

	h3 {
		color: $red;
		width: 100%;
	}

	.infoBlock {
		margin-right: auto;

		&:nth-child(even) {
			margin-right: 0;
			margin-left: auto;
		}

		&.infoBlockSmall {
			margin: 0 0 20px;
			@include tablet() {
				&:nth-child(even) {
					margin-right: 0;
					margin-left: auto;
				}
			}
			@include desktop() {
				&:nth-child(even) {
					margin-right: 0;
					margin-left: 20%;
				}
			}
		}

		h4 {
			color: $red;
		}
	}
}

.infoBlock {
	margin-bottom: 40px;
	width: 75%;
	margin-left: auto;

	&:nth-child(even) {
		margin-right: auto;
		margin-left: 0;
	}

	&.infoBlockSmall {
		justify-self: spac;
		width: 30%;
		margin: 0 0 20px;
		display: inline-block;
		vertical-align: top;

		@include tablet() {
			width: 75%;
			margin-left: auto;
			display: block;

			&:nth-child(even) {
				margin-left: 0;
				margin-right: auto;
			}
		}

		@include desktop() {
			width: 60%;
			&:nth-child(even) {
				margin-left: 20%;
				margin-right: 0%;
			}
		}

		h4 span {
			display: block;
			&:first-child {
				margin-bottom: 10px;
			}
			&:last-child {
				height: 70px;
			}
		}
	}

	h4 {
		margin-bottom: 10px;
		span {
			vertical-align: middle;
			margin-top: -3px;
			margin-right: 5px;
		}
	}
}

.tabs {
	text-align: center;
	button {
		min-width: 100px;
		margin: 0 10px 10px;

		@include tablet() {
			min-width: 100px;
			margin: 0 10px 20px;
		}
	}
}
