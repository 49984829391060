@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.successStories {
	h2 {
		font-size: 3rem;
		line-height: 3.4rem;
		margin-bottom: 0;

		@include desktop() {
			font-size: 4rem;
			line-height: 4.6rem;
		}
	}
}

.categories {
	text-align: center;

	button {
		margin: 0 5px 10px 5px;
	}
}

.successStoryCont {
	grid-column: span 3;
}

.successStory {
	background-color: $deep-green;

	.successStoryImage {
		height: 120px;
		width: 100%;
		overflow: hidden;
		object-fit: cover;

		@include tablet() {
			height: 200px;
		}

		> div {
			object-fit: cover;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.successStoryContent {
		padding: 10px 10px 0px 10px;
		color: $white;

		@include tablet() {
			padding: 20px 20px 10px 20px;
		}

		p {
			margin-bottom: 0;
			color: $white;
		}

		button {
			color: $white;
			font-size: 1.6rem;
		}

		.successStoryPetName {
			font-size: 1.6rem;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
}

.pagination {
	text-align: center;
	margin-top: 20px;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			width: 30px;
			height: 30px;
			color: $white;
			text-align: center;
			margin: 0 5px;

			a {
				width: 30px;
				height: 30px;
				background-color: $deep-green;
				padding-top: 5px;
				border-radius: 20px;
				display: inline-block;
			}

			&.active {
				a {
					background-color: transparent;
					color: $deep-green;
				}
			}
		}
	}
}
