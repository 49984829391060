@import "@/utils/mixins";
@import "@/utils/variables";

.description {
	font-size: 1.8rem;
	margin-bottom: $gutter;
	margin-top: 10px;
	font-weight: bold;
	display: block;

	@include tablet() {
		font-size: 2rem;
	}
}

.error {
	color: $color-error;
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 1.2;
}

.helpCard {
	&.helpCardSmall > div {
		width: 50%;
	}

	p {
		font-size: 1.4rem;
		font-weight: normal;
		color: $deep-green;
		line-height: 1.6rem;
	}

	.helpCardImage {
		width: 100%;
		position: relative;
		margin: 10px auto 0;
		height: 50px;

		svg path {
			fill: $deep-green;
		}
	}
}

.toggleGroup {
	display: flex;
	flex-direction: row;
	background-color: $white;
	overflow: hidden;
	padding: 5px 0;
	border-radius: 5px;
	position: relative;
	margin-bottom: 10px;
	width: 100%;
}

@keyframes fadeIn {
	0% {
		background: transparent;
	}

	100% {
		background: $deep-green;
	}
}

.activeIndicator {
	position: absolute;
	top: 4px;
	left: 0;
	height: calc(100% - 8px);
	width: calc(25% - 10px);
	transform: translateX(5px);
	background: $deep-green;
	border-radius: 3px;
	transition: all 0.4s ease-in-out;
	animation: fadeIn 0.4s;

	&.duo {
		height: calc(100% - 8px);
		width: calc((100% / 2) - 10px);
	}

	&.trio {
		height: calc(100% - 8px);
		width: calc((100% / 3) - 10px);
	}
}
