@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

// SPLIT IMAGE IN FUNNEL
.split-image#funnel-kibble-biscuits-image {
	background-image: url(/images/img-funnel-kibble-mix.jpg);
	@supports (-webkit-clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%))
		or (clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%)) {
		background-image: none;
	}
}

.split-image#funnel-wet-food-image {
	background-image: url(/images/img-funnel-wet-mix.jpg);
	@supports (-webkit-clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%))
		or (clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%)) {
		background-image: none;
	}
}

.split-image#funnel-raw-image {
	background-image: url(/images/img-funnel-raw-mix.jpg);
	@supports (-webkit-clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%))
		or (clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%)) {
		background-image: none;
	}
}

.split-image#funnel-home-prepared-meals-image {
	background-image: url(/images/img-funnel-raw-mix.jpg);
	@supports (-webkit-clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%))
		or (clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%)) {
		background-image: none;
	}
}

.split-image {
	position: relative;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center center;
	width: 60%;
	margin: 0px auto;

	@include tablet() {
		transform: scale(1.1);
		width: 100%;
		margin: 0;
	}

	.split-image__top,
	.split-image__bottom {
		opacity: 0;
		width: 100%;
		height: auto;
	}
	.split-image__top {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 99%;

		@include tablet() {
			height: 97.9%;
		}

		@include tabletLarge() {
			height: 98.9%;
		}
	}
	@supports (-webkit-clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%))
		or (clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%)) {
		background-image: none;
		.split-image__divider {
			height: 0px;
			position: absolute;
			z-index: 3;
			width: calc(100% + 20px);
			border-top: 3px solid $color-primary;
			border-bottom: 3px solid #bd2323;
			left: calc(50% - 3px);
			top: 35%;
			left: -40%;
			transform: rotate(-78.5deg);
			border-radius: 5px;
			padding: 0;
			display: block;
		}
		.split-image__top {
			opacity: 1;
			-webkit-clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
			clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
		}
		.split-image__bottom {
			opacity: 1;
		}
	}
}
