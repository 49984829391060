@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.pagination {
	list-style-type: none;
	margin: 0 auto;
	li {
		list-style-type: none;
		display: inline-block;
		padding: 0;

		button,
		a {
			padding: 10px;
			display: inline-block;

			@include tablet() {
				padding: 15px;
			}
		}
	}
}
