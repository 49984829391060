@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.reviews {
	&.headingLarge {
		h2 {
			font-size: 3rem;
			line-height: 3.4rem;

			&:before {
				width: 60px;
				height: 40px;
			}

			@include tablet() {
				&:before {
					width: 60px;
					height: 40px;
				}
			}

			@include desktop() {
				font-size: 4rem;
				line-height: 4.6rem;

				&:before {
					width: 80px;
					height: 50px;
					top: -32px;
					right: 10px;
				}
			}
		}
	}
	h2 {
		position: relative;
		display: inline-block;

		&:before {
			content: "";
			background-image: url("/images/burst.svg");
			width: 60px;
			height: 40px;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			top: -25px;
			right: 5px;
			transform: rotate(40deg);
		}

		@include tablet() {
			&:before {
				top: -25px;
				right: 5px;
				width: 60px;
				height: 40px;
			}
		}

		@include desktop() {
			&:before {
				top: -25px;
				right: 5px;
				width: 60px;
				height: 40px;
			}
		}
	}
}

// BACKGROUND COLOURS
.background-deep-green {
	background-color: $deep-green;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}
}

.background-green {
	background-color: $light-green;

	h2,
	h3 {
		color: $white;
	}
}

.background-purple {
	background-color: $purple;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}
}
