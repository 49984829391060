@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.timelinePoint {
	padding: 20px 0;
	position: relative;
	overflow: visible;

	@include tablet() {
		padding: 40px 0;
	}

	> div {
		grid-auto-flow: dense;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			right: 78%;
			top: 103%;
			width: 60px;
			height: 70px;
			background-image: url(/images/arrow-drawn-right.svg);
			background-repeat: no-repeat;
			background-size: 100% auto;
			transform: rotate(50deg);
			z-index: 3;

			@include tablet() {
				width: 80px;
				height: 90px;
				right: 90%;
				top: 100%;
			}
		}
	}

	&:first-child {
		padding-top: 0;
		& > div:before {
			right: 75%;
			top: 100%;
			transform: rotate(60deg);

			@include tablet() {
				right: 78%;
				top: 85%;
				transform: rotate(80deg);
			}
		}
	}

	&:nth-child(even) {
		background-color: darken($off-white, 5%);

		& > div:before {
			background-image: url(/images/arrow-drawn-left.svg);
			transform: rotate(140deg) scaleX(-1);
			right: auto;
			left: 78%;
			top: 102%;

			@include tablet() {
				left: 88%;
				top: 100%;
			}
		}

		.image.letter:after {
			background-image: url("/images/letter-off-white-2-i.svg");
		}
	}

	&:last-child {
		padding-bottom: 0;
		& > div:before {
			content: none;
		}
	}

	&.textOnLeft {
		.image {
			@include tablet() {
				grid-column: 7 / span 6;
			}

			@include desktop() {
				grid-column: 8 / span 4;
			}
		}
		.pointContent {
			@include tablet() {
				grid-column: 1 / span 6;
			}

			@include desktop() {
				grid-column: 2 / span 5;
			}
		}
	}

	.pointDate {
		color: $yellow;
		margin-bottom: 5px;
	}

	&.textCentered {
		.pointContent {
			text-align: center;
			@include tablet() {
				grid-column: 3 / span 8;
			}

			@include desktop() {
				grid-column: 4 / span 6;
			}

			h2 {
				text-align: center;
			}
		}
	}
}

.image {
	grid-column: 1 / span 6;
	align-self: center;
	position: relative;

	@include tablet() {
		grid-column: 1 / span 6;
	}

	@include desktop() {
		grid-column: 2 / span 4;
	}

	&.zigZag {
		&:after {
			content: "";
			position: absolute;
			top: 100%;
			width: 100%;
			height: 25px;
			background-image: url("/images/zig-zag-i.svg");
			background-repeat: repeat;
			background-size: 14px 5px;
			background-size: 25px 30px;
			transform: translateY(-20px);
			z-index: 2;
		}
	}

	&.letter {
		&:after {
			content: "";
			background-image: url("/images/letter-off-white-i.svg");
			position: absolute;
			top: 100%;
			width: 100%;
			height: 25px;
			background-repeat: repeat-x;
			background-size: 26px 26px;
			transform: translateY(-13px);
			background-position: center;
			z-index: 2;
		}
	}
}

.pointContent {
	grid-column: 1 / span 6;
	align-self: center;
	text-align: center;

	@include tablet() {
		text-align: left;
		grid-column: 7 / span 6;
	}

	@include desktop() {
		grid-column: 7 / span 5;
	}

	h2 {
		font-size: 3rem;
		line-height: 3.4rem;

		@include desktop() {
			font-size: 4rem;
			line-height: 4.6rem;
		}
	}

	h2,
	h3 {
		text-align: center;

		@include tablet() {
			text-align: left;
		}
	}

	p {
		margin-left: 0;
	}
}

// SIDEICONS
.sideIcons-right-1,
.sideIcons-right-2,
.sideIcons-left-1,
.sideIcons-left-2,
.sideIcons-left-1-and-right-1,
.sideIcons-left-2-and-right-2 {
	@include desktop() {
		&:before {
			content: "";
			position: absolute;
			//transform: translateX(50%);
			left: calc(100% - 12vw);
			height: 120%;
			width: 400px;
			background-size: auto 100%;
			background-position: top center;
			background-repeat: no-repeat;
			top: -10%;
			z-index: 0;
		}
	}
	@include desktopLarge() {
		&:before {
			left: auto;
			right: -180px;
		}
	}
}

.sideIcons-left-1-and-right-1,
.sideIcons-left-2-and-right-2 {
	@include desktop() {
		&:after {
			content: "";
			position: absolute;
			//transform: translateX(50%);
			left: calc(100% - 12vw);
			height: 120%;
			width: 400px;
			background-size: auto 100%;
			background-position: top center;
			background-repeat: no-repeat;
			top: -10%;
			z-index: 0;
		}
	}
	@include desktopLarge() {
		&:after {
			left: auto;
			right: -180px;
		}
	}
}

.sideIcons-right-1:before {
	background-image: url("/images/side-icons-right-1.png");
	left: calc(100% - 12vw);
	@include desktopLarge() {
		left: auto;
		right: -170px;
	}
}

.sideIcons-right-2:before {
	background-image: url("/images/side-icons-right-2.png");
	left: calc(100% - 14vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.sideIcons-left-1:before {
	background-image: url("/images/side-icons-left-1.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -180px;
	}
}

.sideIcons-left-2:before {
	background-image: url("/images/side-icons-left-2.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-left-1-and-right-1 {
	&:before {
		background-image: url("/images/side-icons-left-1.png");
		right: calc(100% - 12vw);
		left: auto;
		@include desktopLarge() {
			right: auto;
			left: -180px;
		}
	}
	&:after {
		background-image: url("/images/side-icons-right-1.png");
		left: calc(100% - 12vw);
		@include desktopLarge() {
			left: auto;
			right: -170px;
		}
	}
}

.sideIcons-left-2-and-right-2 {
	&:before {
		background-image: url("/images/side-icons-left-2.png");
		right: calc(100% - 12vw);
		left: auto;
		@include desktopLarge() {
			right: auto;
			left: -140px;
		}
	}
	&:after {
		background-image: url("/images/side-icons-right-2.png");
		left: calc(100% - 14vw);
		@include desktopLarge() {
			left: auto;
			right: -150px;
		}
	}
}
